import React from "react";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import ScrollAnimation from "react-animate-on-scroll";
import "./styles/FeatureGrid.scss";

const FeatureGrid = (props) => (
  <section className="feature-grid columns is-multiline">
    {props.featuredImages.map((item, index) => (
      <div key={index} className="column is-half">
        <ScrollAnimation
          animateIn="fadeInFromBottom"
          animateOnce={true}
          delay={(index + 1) % 2 === 0 ? 100 : 0}
        >
          <div className="card-image box p-0 is-clipped">
            <PreviewCompatibleImage
              className={"image-square"}
              imageInfo={item}
            />
          </div>
        </ScrollAnimation>
      </div>
    ))}
  </section>
);

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
};

export default FeatureGrid;
