import React from "react";
import "./styles/MainServices.scss";
import { Link } from "gatsby";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

export default function MainServices({ services }) {
  return (
    <div className="main-services-wrapper container mb-6">
      {services.map((item) => (
        <div
          key={item.description}
          className="service-tile box is-clipped is-flex is-flex-direction-column is-align-items-center py-6 px-6"
        >
          <figure className="image is-128x128 mb-3">
            <PreviewCompatibleImage className={"is-rounded"} imageInfo={item} />
          </figure>
          <h2 className="has-text-weight-semibold is-size-2 mb-4">
            {item.title}
          </h2>
          <p className="is-size-5">{item.description}</p>
        </div>
      ))}
      <div className="services-cta mb-2">
        <Link to="/services/">
          <button className="button is-primary is-large is-rounded">
            View Price List
          </button>
        </Link>
      </div>
    </div>
  );
}
