import React from "react";
import "./styles/FeatureCard.scss";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

export default function FeatureCard(props) {
  return (
    <div
      className={`feature-card container box mb-6 is-clipped p-0 ${props.isAlt}`}
    >
      <div className="card is-image-card">
        <div className="">
          <figure className="image">
            <PreviewCompatibleImage
              imageInfo={props.spotlight.image}
              className={"image-square"}
            />
          </figure>
        </div>
      </div>
      <div className="is-content has-text-centered p-6">
        <h2 className="is-size-2 has-text-weight-semibold mb-4">
          {props.spotlight.title}
        </h2>
        <p className="is-size-5">{props.spotlight.description}</p>
      </div>
    </div>
  );
}
