import React from "react";
import "./styles/Location.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { faDirections } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";

export default function Location() {
  const iframe = `<iframe height="100%" width="100%" title="Google Maps Location for Ms. Tang's Nail and Spa" frameborder="0" style="border:0" src="https://www.google.com/maps/embed/v1/place?key=${process.env.GATSBY_GOOGLE_MAP_API_KEY}&q=Ms.+Tang’s+Nails+and+Spa,Santa+Maria+CA" allowfullscreen></iframe>`;

  return (
    <div className="location-wrapper container py-6">
      <div className="location-text mb-5">
        <h2 className="is-size-2 has-text-weight-bold mb-5 has-text-white-bis">
          Location and Hours
        </h2>
        <div className="is-flex is-align-items-flex-start">
          <FontAwesomeIcon
            icon={faMapMarkerAlt}
            className="mr-2 has-text-white-bis is-size-5 mt-1"
          />
          <p className="is-size-5 has-text-weight-semi-bold mb-2 has-text-white-bis">
            1157 E Clark Ave, Ste F, Santa Maria, CA 93455
          </p>
        </div>
        <p className="is-size-5 has-text-weight-semi-bold mb-2 has-text-white-bis">
          <FontAwesomeIcon
            icon={faPhoneAlt}
            className="mr-2 has-text-white-bis"
          />
          805-347-6863
        </p>
        <div className="is-flex is-align-items-flex-start">
          <FontAwesomeIcon
            icon={faClock}
            className="has-text-white-bis is-size-5 mt-1 mr-2"
          />
          <p className="is-size-5 has-text-weight-semi-bold has-text-white-bis">
            Mon - Sat : 9:00AM - 7:30PM <br />
            Sun : 10:00AM - 4:00PM
          </p>
        </div>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.google.com/maps/dir/?api=1&destination=Ms.+Tang’s+Nails+and+Spa,Santa+Maria+CA"
        >
          <button className="button is-primary is-small is-rounded mt-3 is-hidden-tablet">
            Get Directions{" "}
            <FontAwesomeIcon
              icon={faDirections}
              className="ml-2 has-text-white-bis"
            />
          </button>
        </a>
      </div>
      <div
        className="location-content"
        dangerouslySetInnerHTML={{ __html: iframe }}
      ></div>
    </div>
  );
}
