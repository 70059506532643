import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import BookingButton from "../components/BookingButton";
import Location from "../components/Location";

import Layout from "../components/Layout";
import FeatureCard from "../components/FeatureCard";
import FeatureGrid from "../components/FeatureGrid";
import MainServices from "../components/MainServices";
import Social from "../components/Social";
import HeroImage from "../img/Hero.svg";
import { useInView } from "react-intersection-observer";

export const IndexPageTemplate = ({
  title,
  subheading,
  mainpitch,
  featuredImages,
  spotlights,
  services,
}) => {
  const [heroSectionRef, heroSectionInView, entry] = useInView({
    /* Optional options */
    threshold: 0.4,
  });
  return (
    <div>
      <h1 style={{ display: "none" }}>
        Welcome to Ms. Tang’s Nails and Spa | Nail salon | Santa Maria, CA 93455
      </h1>
      <section
        ref={heroSectionRef}
        className="section-hero is-flex is-align-items-center is-clipped"
      >
        {/* <div id="leaves">
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </div> */}
        <div className="is-flex is-align-items-center is-justify-content-space-between container">
          <div className="section-hero-content has-text-left">
            <h1 className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen hero-title mb-2">
              Ms. Tang’s <br />
              Nails and Spa
            </h1>
            <h2 className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen mb-6">
              {subheading}
            </h2>
            {/* <h3 className="is-size-5-widescreen mb-4">
              The Season Of Gift Giving
            </h3> */}
            <a
              href="https://squareup.com/gift/MLCGZTH97XKAS/order"
              target="_blank"
            >
              <BookingButton btnLabel="Order eGift Card" />
            </a>
            <h3 className="is-size-5-widescreen mb-4 pt-6">
              Online booking available
            </h3>
            <Link to="/booking/">
              <BookingButton className="mb-4 pulse-button" />
            </Link>
            <h3 className="is-size-6-widescreen mb-4">
              Walk-Ins welcome. Subject to availability.
            </h3>
          </div>
          <figure className="image hero-image">
            <img src={HeroImage} alt="Nail Salon Illustration" />
          </figure>
        </div>
      </section>
      <section className="section tag-line">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="tile">
                    <h2 className="title is-size-1 is-size-2-mobile">
                      {mainpitch.title}
                    </h2>
                  </div>
                  <div className="tile">
                    <h3 className="subtitle is-text has-text-grey is-size-1 is-size-3-mobile">
                      {mainpitch.subtitle}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="features">
        <h2 className="has-text-centered has-text-weight-bold is-size-1 mb-5 pt-6">
          Spotlight
        </h2>
        {spotlights.map((item, index) => (
          <FeatureCard
            spotlight={item}
            isAlt={(index + 1) % 2 == 0 ? "" : "is-alt"}
            key={"feature-card" + index}
          />
        ))}
      </section>
      <section className="main-services">
        <h2 className="has-text-centered has-text-weight-bold is-size-1 mb-5 pt-6">
          Main Services
        </h2>
        <MainServices services={services} />
      </section>
      <section className="container pb-6">
        <h2 className="has-text-centered has-text-weight-bold is-size-1 mb-5 pt-6">
          Gallery
        </h2>
        <FeatureGrid featuredImages={featuredImages} />
      </section>
      <section className="location">
        <Location />
      </section>
      <section className="social section is-medium">
        <Social />
      </section>
      {!heroSectionInView && (
        <div className="fab">
          <Link to="/booking/">
            <BookingButton />
          </Link>
        </div>
      )}
    </div>
  );
};

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  services: PropTypes.array,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        spotlights={frontmatter.spotlights}
        services={frontmatter.services}
        featuredImages={frontmatter.featuredImages}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        subheading
        mainpitch {
          title
          subtitle
          description
        }
        spotlights {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 800, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        services {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 300, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        featuredImages {
          image {
            childImageSharp {
              fluid(maxWidth: 800, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          description
        }
      }
    }
  }
`;
